import 'public/js/lite-youtube';
$(document).ready(function () {
  function changVideoImg(id) {
    var imgBlock = $('#yt_cover');
    var imgUrl = "https://i.ytimg.com/vi/" + id + "/maxresdefault.jpg";
    imgBlock.attr('src', imgUrl);
  };

  $('.video-list').find('.video-list-item').on('click', function () {
    var id = $(this).attr('data-video-id');
    var content = $(this).find('.video-content-hidden').html();
    $('#yt_video').attr('videoid', id);
    changVideoImg(id);
    $('#videoContent').html(content);
    $('.video-list-item').removeClass('is-active');
    $(this).addClass('is-active');
    setTimeout(() => {
      var videoContentHeight = $('.video-content-main').height();
      if (videoContentHeight >= 160) {
        $('.video-more-btn').css('display', 'flex');
        $('.video-content').addClass('set-height');
      } else {
        $('.video-more-btn').css('display', 'none');
        $('.video-content').removeClass('set-height');
      }
    }, 300);
  });

  $('.video-more-btn').on('click', function () {
    $('.video-more-btn').css('display', 'none');
    $('.video-content').removeClass('set-height');
  });
});
