import anime from 'animejs/lib/anime.es.js';

$(function () {
  const bn_left1 = "M0,436.6c38.63-8.43,73.72-26.35,102.69-51.19,50.34-43.16,82.24-107.22,82.24-178.73,0-54.94-18.83-105.49-50.38-145.54-14.52-18.43-25.04-39.24-31.33-61.14H0v436.6Z",
        bn_left2 = "M0,436.6c38.63-8.43,94.2-29.34,119.03-58.32,50.68-59.13,16.89-121.63,0-187.52-13.65-53.22-2.27-85.58,33.79-121.63,15.2-15.2,43.46-47.23,37.17-69.12H0v436.6Z",
        bn_left3 = "M0,322.53c31.19,40.54,80.39,33.47,110.59,10.14,37.17-28.72,74.33-65.88,57.44-131.77-13.65-53.22-5.64-80.51,30.41-116.57,15.2-15.2,73.87-62.43,67.57-84.33H0v322.53Z",
        bn_left4 = "M0,285.36c31.19,40.54,127.69,48.68,157.89,25.34,37.17-28.72,70.05-59.13,53.15-125.01-13.65-53.22-64.75-70.44-43.02-116.57,10.33-21.93,13.06-47.23,6.76-69.12H0v285.36Z";
  const bn_right1 = "M630,0s-55.55-13.88-55.51,0c.1,34.21-19.42,75.74-38.54,92.23-22.98,19.82-83.73,79.45-94.88,130.85-10.6,48.88-18.97,101.47,3.19,154.88,24.31,58.59,47.56,96.05,169.34,140.41,5.26,1.92,10.95,2.1,16.4,2.85V0Z",
    bn_right2 = "M630,76s-58.59,7-69.8,66c-6,74,30.8,73,11.8,110-22.98,19.82-67.85,58.6-79,110-10.6,48.88,13.49,85,33.16,109,29.84,32,62.09,37.96,87.44,47.37,5.26,1.92,10.95,2.1,16.4,2.85V76Z",
    bn_right3 = "M630-55s-140.8,14-152,73c-21.16,99.17,74.64,127.68,74.67,153.68,4.33,40.32-10.65,66.08-26.51,108.32-13,32,1.81,63.58,13.84,83,19.43,19.7,48.25,31.59,73.6,41,5.26,1.92,10.95,2.1,16.4,2.85V-55Z",
    bn_right4 = "M630-58.7s-243.56-17.74-243.48,82.78c.04,59.25,59.77,62.5,103.05,82.78,42.41,19.87,59.97,65.32,51.53,97.98-7.06,27.28-3.57,51.19,19.43,72.64,14.76,13.77,59.3,18.2,69.48,17.52,8.45-.56,32.98-3.25,37.11-8.95l-37.11-344.76Z";
  anime({
    targets: '#banner_left',
    easing: 'linear',
    duration: 20000,
    loop: true,
    d: [
      { value: [bn_left1, bn_left2] },
      { value: bn_left3 },
      { value: bn_left4 },
      { value: bn_left1 },
    ],
  });

  anime({
    targets: '#banner_right',
    easing: 'linear',
    duration: 22000,
    loop: true,
    d: [
      { value: [bn_right1, bn_right2] },
      { value: bn_right3 },
      { value: bn_right4 },
      { value: bn_right1 },
    ],
  });
  var bn_left_circle = anime.timeline({
    targets: '#banner_left_circle',
    duration: 8500, // Can be inherited
    easing: 'linear',
    direction: 'alternate', // Is not inherited
    loop: true // Is not inherited
  });
  bn_left_circle.add({
    translateX: 50,
    translateY: 20,
    opacity: 0.4,
  })
  .add({
    translateX: 110,
    translateY: 10,
    opacity: 0.5,
    scale: 0.6,
  })
  .add({
    translateX: 70,
    translateY: 15,
    scale: 0.8,
    opacity: 0.8,
  })
  .add({
    translateX: 30,
    translateY: 50,
    scale: 1,
    opacity: 0.6,
  })
  .add({
    translateX: 50,
    translateY: 10,
    scale: 0.8,
    opacity: 0.3,
  })
  .add({
    translateX: 110,
    translateY: 20,
    scale: 0.7,
    opacity: 0.5,
  });

  var bn_right_circle = anime.timeline({
    targets: '#banner_right_circle',
    duration: 8000, // Can be inherited
    easing: 'linear',
    loop: true // Is not inherited
  });
  bn_right_circle.add({
    translateX: 10,
    translateY: 40,
    opacity: 0.5,
    scale: 1,
  })
  .add({
    translateX: 20,
    translateY: -40,
    scale: 1.2,
    opacity: 0.8,
  })
  .add({
    translateX: 10,
    translateY: 88,
    scale: 0.9,
    opacity: 0.4,
  })
  .add({
    translateX: -10,
    translateY: 60,
    scale: 1.2,
    opacity: 0.8,
  })
  .add({
    translateX: 20,
    translateY: 40,
    opacity: 0.5,
    scale: 1,
  });


});
